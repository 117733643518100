body {
  margin-top: 60px;
  background-color: #FDE5C1;
  background-image: url("./components/final_background.png");
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}



